/* nomalizing */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
  font-family: 'Noto Sans KR', sans-serif;
}


@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);
.nanummyeongjo * {
 font-family: 'Nanum Myeongjo', serif;
}

/* material-icons */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

body {
  /* overflow-x: hidden; */
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', serif;
  }

/* 오른쪽 공간 여백문제 해결 */
body > div {
  overflow-x: hidden;
}



body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
 }

a {
  color: inherit;
  text-decoration: none;
 }

 /* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
 }

.bg-green {
  background-color: green;
}

 
.c-white {
  color: white;
}

/* custom */
:root {
  --nav-index: 2;
  --navbar-height: 60px;
  --card-font: rgba(80, 80, 80, 1)  ;
  --card-font-shadow: rgba(50, 50, 50, 1)  ;
  --company-color: #FF8200;
}

:root {
  --page-width: 1400px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}


/* ======================================================== */
/* Header  */

.header {
  height: 85px;
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 500;
  transition: all .4s;
}

.header a{
  cursor: pointer;
}


.header__con {
  height: 80px;
  margin: 0 auto;
  padding: var(--page-padding-pc);
  display: block;
  z-index: 300 !important;
}

.header__logo-con {
  height: 100%;
  position: relative;
}

.header__hamburger {
  position: absolute;
  right: 0;
  top: 4vh;
  transition: 0.5s ease-in;

  /* 동그라미 배경 */
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: rgb(1,1,1, 0.5);
  /* background-color: rgb(2, 137, 215); */
  border-radius: 50%;
  overflow: hidden;

  cursor: pointer;
}

.header__hamburger a {
  /* 동그라미 배경 안에 텍스트 중앙배치 */
  display: inline-block;
  margin-top: -2px;


  color: #fff;
  font-size: 40px;
  font-weight: 300;
}


.header__logo-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo > a {
  font-size: 22px;
}

.header__category-con {
  height: auto; /* 세로 길이만큼 높이를 설정  */
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}


.header__category-con > li {
  height: 100%;
  width: 70%;
  margin: 0 auto;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}


/* 메인메뉴 */
.header__category-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #707070;
  justify-content: flex-start;
  font-size: 25px;
  padding: 20px 0;
}

.header__category-con > li > a::before {
  content: '+';
  padding: 0 7px;
  margin-right: 20px;
  font-size: 15px;
  font-weight: 300;
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.header-unclick {
  height: 80px !important;
}

.header-click {
  height: 90% !important;
  background: rgba(30,30,30,.9); 

}

.header__category-sub-con {
  display: none;
  padding-top: 10px;
}

.block  {
  display: block !important;
}

.header__category-sub-con a {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  color: #cdcccc;  
  transition: all .5s ease-in;
}

.header__category-sub-con a:hover {
  color: #fff;
}

.header__icon-con {
  margin-top: 30px;
  font-size: 12px;
  color: #cdcccc;
}


.header__icon-con {
  padding-left: 40px;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 11px;
  font-weight: 500;
}

.header__icon-con li {
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header__icon-con li:not(:nth-last-child(1)) {
  border-right: 0.1px solid #fff;
}


@media (max-width: 1023px) {
  .header__con {
    height: 60px;
    padding: var(--page-padding-mo);
  }

  .header-unclick {
    height: 60px !important;
  }
  
  .header__hamburger {
    top: 3vh;

    width: 30px;
    height: 30px;  
  }

  .header__hamburger a {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
  }
  
  .header__category-con > li {
    width: 100%;
  }  

  /* 메인메뉴 */
  .header__category-con > li > a {
    font-size: 18px;
    padding: 10px 0;
  }

  /* 서브메뉴 */
  .header__category-sub-con a {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
  }

}

/* ========================================================= */
/* footer.jsx */

.footer {
  padding: 20px 0;

  /* custom */
  background: black;
  color: lightgray;

}

.footer__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  /* width: 100%; */
  max-width: var(--page-width);

  display: flex;
  position: relative;
}

.footer__logo {
  margin-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  /* custom */
  font-family: 'Nanum Myeongjo', serif;
  font-weight: bold;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 13px;
  line-height: 24px;
}

.footer__txt02 {
  font-size: 15px;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      padding-bottom: 50px;
  }
  .footer__con {
      padding: var(--page-padding-tab);
      flex-direction: column;
  }
  .footer__logo {
      margin-bottom: 10px;
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }    
  .footer__law-con {
      right: 50%;
      transform: translateX(30%);
      bottom: -30px;
  }
}
@media (max-width: 767px) {
  .footer__con {
      padding: var(--page-padding-mo);
  }
}
@media (max-width: 480px) {
}

/* ========================================================= */

.license__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.license__grid-con { 
  /* margin: 40px 0 40px -10px; */
  display: flex;
  flex-wrap: wrap;
}

.license__grid-item {
  margin: 30px 10px 30px 10px;
  width: calc( (100% - 3 * 20px) / 3 );
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  position: relative;
}

.license__source-name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #428ef9;
  cursor: pointer;
}

.license__source-type {
  letter-spacing: -1px;
  opacity: 0.7;
}


.license__source-writer {
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 22px;
}


@media (max-width: 1023px) {
  .license__con {
    padding: var(--page-padding-tab);
  } 

  .license__txt-sub {
    font-size: 18px;
  }

}
@media (max-width: 767px) {
  .license__con {
    padding: var(--page-padding-mo);
  }

  .license__grid-item {
    margin: 15px 0px 15px 0px;
    margin-bottom: 15px;
    width: calc( (100% - 2 * 10px) / 2 );
  }

  .license__grid-item:nth-child(2n-1) {
    margin-right: 20px;
  }

  .license__source-name {
    font-size: 18px;
  }

  
  .license__source-type {
    font-size: 15px;
  }

  .license__source-writer {
    font-size: 15px;
    font-weight: 400;
  }

 
}
@media (max-width: 480px) {
}

/* ======================================================== */
.bg-black {
  background: black;
}
.max-h100vh {
  max-height: 100vh;
}


.intro-spot {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.intro-spot__con {
  position:relative;
  width: 100%;
  height: 100%;
}

.bg-gray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}


.intro-spot__txt-con {
  position: absolute;
  left: 5%;
  top: 35%;
  text-align: left;
  z-index: 100;
}


.intro-spot__txt-con span {
  display: inline-block;
  color: #fff;
  /* text-shadow: 1px 1px 1px rgba(200, 200, 200, 1); */
}

.intro-spot__txt-sub01,
.intro-spot__txt-sub02 {
  font-size: 100px;
  height: 130px; /* 폰트사이즈보다 더 높아야 예쁨 */
  font-weight: 600;
  overflow-y: hidden;
  letter-spacing: -5px;  
}


.intro-spot__txt-title {
  margin-top: 0px;
  font-size: 120px;
  height: 150px; /* 폰트사이즈보다 더 높아야 예쁨 */
  font-weight: 600;
  overflow-y: hidden;
}


.intro-spot__scroll {
  position: absolute;
  top: 45%;
  right: 5%;
  width: 60px;
  height: 110px;
  border: 1px solid #fff;
  border-radius: 60px;
  z-index: 100;
}

.intro-spot__dot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: scrolldown 2s infinite;
}

@keyframes scrolldown {
  from {
    opacity: 1;
    top: 10%;
  }
  to {
    opacity: 0;
    top: 50%;
  }
}


@media (max-width:834px) {
  .intro-spot {
    height: 100vh;
  }  
  .intro-spot__txt-con {
    left: 8%;
    top: 10%;
  }
  .intro-spot__txt-sub01,
  .intro-spot__txt-sub02 {
    font-size: 48px;
    height: 65px; /* 폰트사이즈보다 더 높아야 예쁨 */
    font-weight: 600;
    letter-spacing: -3px;
  }
  .intro-spot__txt-title > span {
    font-size: 70px;
  }
  .intro-spot__scroll {
    top: 83%;
    right: 8%;
    width: 40px;
    height: 70px;
  }
  .intro-spot__dot {
    width: 8px;
    height: 8px;
    animation: scrolldown 2s infinite;
  }
  @keyframes scrolldown {
    0% { /* 모바일에서 ani반복할 때, 점이 아래로 이동하는 오류 해결 */
      opacity: 0;
      top: 75%;
    }  
    20% {
      opacity: 1;
      top: 75%;
    }  
    100% {
      opacity: 0;
      top: 40%;
    }
  } 
}




/* -------------------------------------------------  */
/* intro-brand */

.intro-brand {
  /* position: relative; */
  padding: 200px 0 0 0;
} 

.intro-brand__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  max-width: var(--page-width);
}

.intro-brand__txt-con {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  /* height: 350px; */
  /* overflow-y: hidden; */
  transition: all 1s;
}

.intro-brand__triangle {
  position: absolute;
  left: -10px;
  top: 0px;
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid rgb(16, 142, 215);
  border-left: 5px solid rgb(16, 142, 215);
  border-right: 5px solid transparent;
}

.intro-brand__txt-title,
.intro-brand__txt-sub {
  display: block;
  font-size: 42px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -1px;
  /* text-shadow: 1px 1px 2px #afafaf; */
  word-break: keep-all;
 }

 .intro-brand__txt-sub {
  display: inline-block;
  margin-top: 70px;
  /* margin-bottom: 60px; */
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
 }

 .intro-brand__txt-sub > span {
  /* font-weight: 600; */
 }

 .typing-txt {
  border-bottom: 4px solid rgb(16, 142, 215);
 }

.typing-txt::after {
  content: '';
  margin-left: 1px;
  border-right: 3px solid #111;
  animation: blink 0.5s infinite;
}

@keyframes blink {
  from {
    border-right: 4px solid #111;
  }
  to {
    border-right: 4px solid #aaa;
  }
}


@media (max-width:834px) {
  .intro-brand {
    padding: 80px 0 0 0;
  }

  .intro-brand__con {
    padding: var(--page-padding-mo);
  }

  .intro-brand__triangle {
    left: -5px;
    border-bottom: 4px solid transparent;
    border-top: 4px solid rgb(16, 142, 215);
    border-left: 4px solid rgb(16, 142, 215);
    border-right: 4px solid transparent;  
  }

  .intro-brand__txt-title,
  .intro-brand__txt-sub {
    /* display: block; */
    font-size: 25px;
    /* font-weight: 700; */
    line-height: 38px;
    /* letter-spacing: -2px; */
  }

  .intro-brand__txt-sub {
    /* display: inline-block; */
    margin-top: 40px;
    /* margin-bottom: 60px; */
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }

  .typing-txt {
    border-bottom: 3px solid rgb(16, 142, 215);
  }
}

/* -------------------------------------------------  */

.fixed-bg-wrap-wrap {
  margin-top: 120px;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0); /* fixed일 때) 모바일 스크롤시 떨림 해결 */
}

.fixed-bg-wrap {  
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fixed-bg {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* 100%가 아닌 100vh이어야 img가 안 잘림 */
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}

@media (max-width:834px) {
  .fixed-bg-wrap-wrap {
    margin-top: 50px;
    height: 280px;
  }
  .fixed-bg {
    width: 200%;
    height: 200%;
  }

}

/* -------------------------------------------------  */

.intro-detail {
  padding-top: 150px;
  margin-left: 8vw;
}

.intro-detail__con {
}

.intro-detail__header-con {
  position: relative;
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;
}

.intro-detail__content-con {
  margin-top: 80px;
  display: flex;
  width: 100%;
}

.intro-detail__content-txt {
  width: 48%;
  /* padding-right: 5%; */
}

.intro-detail__content-txt-item {
  /* margin-bottom: 80px; */
  padding: 35px 0;
  opacity: 0.3;
  transition: opacity 0.3s;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.opacity-1 {
  opacity: 1;
  border-bottom: 1px solid #111;

}

.opacity-point3 {
  opacity: 0.3;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.intro-detail__content-txt-title {
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -1px;
}

.intro-detail__content-txt-sub {
  margin-right: 180px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -1px;
  word-break: keep-all;
}

.intro-detail__content-more > a {
  margin-top: 20px;
  display: inline-block;
  height: 100%;
  font-size: 25px;
  font-weight: 500;
  opacity: 0.1;
  cursor: pointer;
}

.intro-detail__content-img-con {
  position: relative;
  width: 52%;
  margin-top: -30px;
  height: 900px;
}

.intro-detail__triangle {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 0;
  height: 0;
  border-bottom: 65px solid transparent;
  border-top: 65px solid #fff;
  border-left: 65px solid #fff;
  border-right: 65px solid transparent;
  z-index: 100;
}

.intro-detail__content-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.intro-detail__content-img > img {
  position: absolute;
  left : 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.intro-detail__img-wrap-mo {
  display: none;
}

@media (max-width:834px) {
  .intro-detail {
    padding-top: 80px;
    margin-left: 0;
  }
  .intro-detail__con {
    padding: var(--page-padding-mo);
  }
  .intro-detail__header-con {
    font-size: 25px;
    /* font-weight: 700; */
    line-height: 38px;
    /* letter-spacing: -1px; */
  }
  .intro-detail__content-con {
    margin-top: 20px;
    /* display: flex; */
    /* width: 100%; */
  }  
  .intro-detail__content-txt {
    width: 100%;
  }  
  .intro-detail__content-txt-item {
    /* opacity: 1 !important; */
    padding: 30px 0;
  }

  .intro-detail__content-txt-item:nth-child(3) {
    border-bottom: none;
  }

  .intro-detail__img-wrap-mo {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }

  .intro-detail__img-wrap-mo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #333;
  }

  .intro-detail__content-img-con {
    display: none;
  }
  .intro-detail__content-txt-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -1px;
  }
  .intro-detail__content-txt-sub {
    margin-right: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    /* letter-spacing: -1px; */
    /* word-break: keep-all; */
  }
  .btn-more-static {
    margin-top: 5px;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    /* text-align: center; */
    text-align: left;
    cursor: pointer;
  }
  
  
}

/* -------------------------------------------------- */
.intro-pf {
  padding-top: 150px;
  margin-left: 8vw;
}

.intro-pf__con {
  width: 100%;
  /* overflow-x: hidden; */
}

.intro-pf__title {
  position: relative;
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;

  text-align: right;
  margin-right: 8vw;
}

.intro-pf__grid-con {
  margin-top: -20px;
  display: flex;
  flex-wrap: nowrap;
  margin-left: -20px;
}

.intro-pf__grid-item {
  margin: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.intro-pf__box-img {
  width: calc( (100vw) / 5.5 );
  /* 이미지 비율 (가로/세로) */
  aspect-ratio: 8/10; 
  object-fit: cover;
  pointer-events: none;
}


.intro-pf__grid-title {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
}

.intro-pf__grid-sub {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

@media (max-width:834px) {
  .intro-pf {
    padding-top: 80px;
    margin-left: 0;
  }
  .intro-pf__con {
    padding: var(--page-padding-mo);
  }
  .intro-pf__title {
    font-size: 25px;
    /* font-weight: 700; */
    line-height: 38px;
  
    text-align: left;
    margin-right: 0;
  }
  .intro-pf__grid-con {
    margin-top: 40px;
  }
  .intro-pf__grid-item {
    margin: 0 20px !important ; /* js 초기화 */
    height: 100%;
  }
  .intro-pf__box-img {
    width: calc( (100vw) / 1.3 );
  }
  .intro-pf__grid-title {
    font-size: 20px;
    font-weight: 600;
  }
  .intro-pf__grid-sub {
    margin-bottom: 30px;
    line-height: 28px;
  }  
}

/* -------------------------------------------------- */
.intro-review {
  padding-top: 0px;
  margin: 0 8vw;
  margin-top: 0px;
}

.intro-review__con {
}

.intro-review__header-con {
  position: relative;
}

.intro-review__header-title {
  position: relative;
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;
}

.btn-more {
  position: absolute;
  bottom: 0;
  right: 0;

  font-size: 25px;
  font-weight: 700;
  letter-spacing: -1px;
  cursor: pointer;
}

.intro-review__txt-con {
  padding-top: 60px;
}

.intro-review__txt-item {
  padding: 40px 0;
  border-bottom: 1px solid rgb(150,150,150, 0.5);
}

.intro-review__txt-item > div {
  max-width: 600px;
  width: 100%;
  padding: var(--page-padding-pc);
  margin: 0 auto;
  text-align: center;
}

.intro-review__stars > span {
  font-size: 35px;
  color: gold;
  text-shadow: 3px 3px 2px orangered;
}

.intro-review__txt-title {
  margin-top: 20px !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -1px;
  word-break: keep-all;
}

.intro-review__txt-sub {
  margin-top: 20px !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -1px;
  color: rgba(100, 100, 100, 0.6);
}

@media (max-width:834px) {
  .intro-review {
    padding-top: 80px;
    margin: 0;
  }
  
  .intro-review__con {
    padding: var(--page-padding-mo);
  }
  
  .intro-review__header-con {
    position: relative;
  }
  
  .intro-review__header-title {
    font-size: 25px;
    line-height: 38px;
  }
  .intro-review__txt-con {
    padding-top: 20px;
  }
  .intro-review__txt-item {
    padding: 30px 0;
  }
  .intro-review__txt-item:nth-child(3) {
    /* padding: 40px 0; */
    border-bottom: none;
  }
  
  .intro-review__txt-item > div {
    max-width: 300px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
  .intro-review__stars > span {
    font-size: 30px;
    text-shadow: 2px 2px 1px orangered;
    letter-spacing: -2px;
  }
  .intro-review__txt-title {
    margin-top: 15px !important;
    font-size: 18px;
    line-height: 30px;
  }
  .intro-review__txt-sub {
    margin-top: 10px !important;
    font-size: 16px;
    font-weight: 300;
  }    
}

/* -------------------------------------------------- */
.intro-img-wrap {
  position: relative;
  margin-top: 100px;
  overflow: hidden;
  height: 105vh;
}

.intro-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 2s;
}

.intro-img a {
  cursor: pointer;
}


.intro-img:hover {
  /* transform: scale(1.1); */
}


.intro-img__txt {
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translateY(-50%);

  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;

  z-index: 100;
}

.intro-img__txt span {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
  border: 1px solid rgb(255,255,255, 0.5);
  border-radius: 30px;
  cursor: pointer;
}

@media (max-width:834px) {
  .intro-img-wrap {
    /* position: relative; */
    margin-top: 60px;
    overflow: hidden;
    height: 90vh;
  }
  
  .intro-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: translateX(10px); */
    transition: all 2s ease;
  }

  .img-position-before {
    object-position: -100px 0;
  }

  .img-position-after {
    object-position: -200px 0;
  }

  .intro-img__txt {
    left: 33%;
    /* top: 50%; */
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
  }
  .intro-img__txt span {
    font-size: 16px;
    font-weight: 400;
    padding: 9px 13px;
    border-radius: 20px;
  }


}

/* ========================================================= */

.overflow-visible {
  overflow: visible !important;
}

.detail-page__banner-con {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;     
}

.detail-page__banner-con > div {
  padding-top: 240px;
  height: 100%;
  text-align: center;
  font-size: 45px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .detail-page__banner-con {
    height: 250px;
  }

  .detail-page__banner-con > div {
    padding-top: 130px;
    font-size: 34px;
  } 
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}

/* ========================================================= */



.detail-page__btn {
  height: 60px;
  background-color: #f7f4f4;
  border-bottom: 1px solid #e1dfdf;
}

.detail-page__btn-con {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: var(--page-width);
  display : flex;
}

.detail-page__btn-con > li > a {
  height: 100%;
  padding: 0 20px;
  border-left: 1px solid #e1dfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.detail-page__btn-menu,
.detail-page__btn-submenu {
  height: 60px;
  width: 250px;
  overflow: hidden;
}

.detail-page__btn-submenu {
  border-right: 1px solid #e1dfdf;
  z-index: 100;
}

.detail-page__btn-submenu span {
  color: #707070;
  font-weight: 800;
}

.detail-page__btn-submenu li > a {
  background-color: #f7f4f4;
  border: 1px solid #e1dfdf;
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 50px;
  font-weight: 400;
}

.detail-page__btn-submenu li:hover > a {
  background-color: #383433;
  color: #fff;
}

.detail-page__con {
  padding: var(--page-padding-pc);
  margin: 40px auto;
  /* width: 100%; */
  max-width: 1200px;

  font-size: 15px;
  line-height: 30px;
  color: #707070;
}

.detail-page__title-con {
  margin: 80px auto;
} 

.detail-page__title-main,
.detail-page__title-sub {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.detail-page__title-sub {
  padding-top: 25px;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 1023px) {
  .detail-page__con {
    padding: var(--page-padding-tab);
    margin: 50px auto;
  }

  .detail-page__title-con {
    margin: 0px auto;
    margin-bottom: 30px;
  } 
  

  .detail-page__title-main {
    font-size: 30px;
  }

  .detail-page__title-sub {
    padding-top: 10px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .detail-page__con {
    padding: var(--page-padding-mo);
  }
}


/* ========================================================= */

.introduction__logo-wrap {
  margin-top: 120px;
  margin-bottom: 70px;
  text-align: center;
  font-size: 90px;
  line-height: 80px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #111;
  font-family: 'Nanum Myeongjo', serif;
  text-shadow: 4px 4px 2px #aaa;
}

/* .introduction__logo-wrap > img {
  width: 500px;
} */

.introduction__txt {
  padding: 50px 0 40px 0;
}

.map__txt {
  padding-top: 20px;
}

.introduction__txt > div {
  margin: 50px 0 30px 0;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #111;
}

.introduction__txt img {
  margin: 20px auto;
  max-width: 600px;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.map__txt img {
  margin: 0 auto;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  display: block;
  border: 3px solid #111;
  border-radius: 20px;
  box-sizing: border-box;
}

.introduction__txt > ul {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 600px;
  width: 100%;
  border: 2px solid #dfdfdf;
  box-sizing: border-box;
}

.introduction__txt > ul > li {
  padding: 7px 20px;
  color: #2f2f2f;
}

.introduction__txt > ul > li:not(:last-child) {
  border-bottom: 2px solid #dfdfdf;
}



@media (max-width: 767px) {
  .introduction__logo-wrap {
    /* margin-top: 120px;
    margin-bottom: 70px; */
    /* text-align: center; */
    font-size: 70px;
    font-weight: 500;
    letter-spacing: -2px;
    text-shadow: 3px 3px 2px #aaa;
  }

  .map__txt img {
    padding: 10px;
    max-width: 400px;
    width: 100%;
    border: 3px solid #111;
    border-radius: 20px;
  }
  

  /* .introduction__logo-wrap > img {
    width: 300px;
  } */
}

/* ========================================================= */

.ourservice__img-con {
  display: flex;
  margin: 0 -10px;
}

.ourservice__img-item {
  box-sizing: border-box;
  background-size: 105%;
  background-position: center;
  transition: background-size .3s ease;

  width: calc(25% - 20px);
  margin: 0 10px;
  position: relative;
}

.bg-gray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.ourservice__img-item::after {
  content: '';
  display: block;
  padding-bottom: 135%;
}


.ourservice__img-item:hover {
  background-size: 120%;
}

.ourservice__img-txt-con {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: white;
  z-index: 100;
}

.ourservice__header-con {
  margin-top: 100px;
  position: relative;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -1px;
  color:#333;
}

.ourservice__content-con {
  margin-top: 80px;
  display: flex;
  width: 100%;
}

.ourservice__content-txt {
  width: 50%;
  /* padding-right: 5%; */
}

.ourservice__content-txt-item {
  /* margin-bottom: 80px; */
  padding: 35px 0;
  opacity: 0.3;
  transition: opacity 0.3s;
  color: #333;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.opacity-1 {
  opacity: 1;
  border-bottom: 1px solid #111;

}

.opacity-point3 {
  opacity: 0.3;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.ourservice__content-txt-title {
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -1px;
}

.ourservice__content-txt-sub {
  margin-right: 100px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -1px;
  word-break: keep-all;
}

.ourservice__content-more > a {
  margin-top: 20px;
  display: inline-block;
  height: 100%;
  font-size: 25px;
  font-weight: 500;
  opacity: 0.1;
  cursor: pointer;
}

.ourservice__content-img-con {
  position: relative;
  width: 50%;
  margin-top: -30px;
  height: 900px;
}

.ourservice__triangle {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 0;
  height: 0;
  border-bottom: 65px solid transparent;
  border-top: 65px solid #fff;
  border-left: 65px solid #fff;
  border-right: 65px solid transparent;
  z-index: 100;
}

.ourservice__content-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ourservice__content-img > img {
  position: absolute;
  left : 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.ourservice__img-wrap-mo {
  display: none;
}

@media (max-width:834px) {
  .ourservice__con {
    padding: var(--page-padding-mo);
  }
  .ourservice__header-con {
    font-size: 25px;
    /* font-weight: 700; */
    line-height: 38px;
    /* letter-spacing: -1px; */
  }
  .ourservice__content-con {
    margin-top: 20px;
    /* display: flex; */
    /* width: 100%; */
  }  
  .ourservice__content-txt {
    width: 100%;
  }  
  .ourservice__content-txt-item {
    /* opacity: 1 !important; */
    padding: 30px 0;
  }

  .ourservice__content-txt-item:nth-child(4) {
    border-bottom: none;
  }

  .ourservice__img-wrap-mo {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }

  .ourservice__img-wrap-mo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #333;
  }

  .ourservice__content-img-con {
    display: none;
  }
  .ourservice__content-txt-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -1px;
  }
  .ourservice__content-txt-sub {
    margin-right: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    /* letter-spacing: -1px; */
    /* word-break: keep-all; */
  }
  .btn-more-static {
    margin-top: 5px;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    /* text-align: center; */
    text-align: left;
    cursor: pointer;
  }
}

@media (max-width: 1023px) {
  .ourservice__img-txt-con {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .ourservice__img-con {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
  }

  .ourservice__img-item {
      width: calc(50% - 10px);
      margin: 5px;
  }
}

/* ========================================================= */

.service__con {
  display: flex;
  flex-direction: row;
}

/* .service__img-box {
  width: 35%;
  object-fit: cover;
  object-position: center;
} */

.service__img-box {
  width: 35%;
  /* 이미지 비율 (가로/세로) */
  aspect-ratio: 8/10; 
  object-fit: cover;
}

.service__txt {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .service__con {
    flex-direction: column;
  }

  .service__con > img {
    width: 100%;
    height: 300px;
  }
  
  .service__txt {
    margin-left: 0;
    margin-top: 30px;
  }
}

/* ========================================================= */

.portfolio__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.portfolio__grid-con { 
  /* margin: 40px 0 40px -10px; */
  display: flex;
  flex-wrap: wrap;
}

.portfolio__grid-item {
  margin: 10px;
  width: calc( (100% - 3 * 20px) / 3 );
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.portfolio__img-box {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-radius: 10px; */
}

.portfolio__img-box::after {
  content: '';
  display: block;
  padding-bottom: 75%;
}

@media (max-width: 1023px) {
  .portfolio__con {
    padding: var(--page-padding-tab);
  } 

  .portfolio__txt-sub {
    font-size: 18px;
  }

}
@media (max-width: 767px) {
  .portfolio__con {
    padding: var(--page-padding-mo);
  }

  .portfolio__grid-item {
    margin: 0;
    margin-bottom: 15px;
    width: calc( (100% - 2 * 5px) / 2 );
  }

  .portfolio__grid-item:nth-child(2n-1) {
    margin-right: 10px;
  } 
}
@media (max-width: 480px) {
}


/* ========================================================= */

.reason__con {
  text-align: center;
}

.img-it {
  width: 80%;
  margin-bottom: 40px;
  border-radius: 20px;
}

.reason__box-con {
  display: flex;
  margin: 0 -30px;
}

.reason__box-item {
  width: calc(33% - 120px);
  margin: 0 60px;
  text-align: left;
}

/* 하단의 텍스트임 */
.reason__box-txt01 {
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: #006aff;
}

.reason__box-txt02 {
  padding-bottom: 20px;
  font-size: 25px;
  color: #111;
}

.reason__box-txt03 {
  font-size: 18px;
  line-height: 30px;
}

@media (max-width: 1023px) {
  .reason {
      padding: 64px 0;
  }
  .reason__txt01 {
      font-size: 16px;
      padding-bottom: 12px;
  }
  .reason__txt02 {
      padding-bottom: 30px;
      font-size: 22px;
      line-height: 38px;
  }
  .reason__box-item {
      width: calc(33% - 60px);
      margin: 0 30px;
      text-align: left;
  }

  .reason__box-txt01 {
      padding-bottom: 10px;
  }
  
  .reason__box-txt02 {
      padding-bottom: 10px;
      font-size: 24px;
  }
  
  .reason__box-txt03 {
      font-size: 16px;
      line-height: 26px;
  }    
}
@media (max-width: 767px) {
  .img-it {
      width: 100%;
      margin-bottom: 40px;
  }
  .reason__box-con {
      flex-direction: column;
      margin: 0;
      align-items: center;
  }
  .reason__box-item {
      width: 100%;
      max-width: 300px;
      margin: 0;
      padding-bottom: 40px;
  }
  
  

}
@media (max-width: 480px) {
}
/* ------------------------------------------------- */
.review-page__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  /* width: 100%; */
  max-width: var(--page-width);
}

.review-page__txt-item {
  padding: 40px 0;
  border-bottom: 1px solid rgb(150,150,150, 0.5);
}

.review-page__txt-item:nth-child(1) {
  margin-top: -40px;
}

.review-page__txt-item > div {
  max-width: 600px;
  width: 100%;
  padding: var(--page-padding-pc);
  margin: 0 auto;
  text-align: center;
}

.review-page__stars > span {
  font-size: 35px;
  color: gold;
  text-shadow: 3px 3px 2px orangered;
}

.review-page__txt-title {
  margin-top: 20px !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -1px;
  word-break: keep-all;
}

.review-page__txt-sub {
  margin-top: 20px !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -1px;
  color: rgba(100, 100, 100, 0.6);
}

@media (max-width:834px) {
  .review-page {
    padding-top: 80px;
    margin: 0;
  }
  
  .review-page__con {
    padding: var(--page-padding-mo);
  }
  
  .review-page__txt-con {
    padding-top: 20px;
  }
  .review-page__txt-item {
    padding: 30px 0;
  }
  .review-page__txt-item:nth-child(3) {
    /* padding: 40px 0; */
    border-bottom: none;
  }
  
  .review-page__txt-item > div {
    max-width: 300px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
  .review-page__stars > span {
    font-size: 30px;
    text-shadow: 2px 2px 1px orangered;
    letter-spacing: -2px;
  }
  .review-page__txt-title {
    margin-top: 15px !important;
    font-size: 18px;
    line-height: 30px;
  }
  .review-page__txt-sub {
    margin-top: 10px !important;
    font-size: 16px;
    font-weight: 300;
  }    
}




/* -------------------------------------------------  */
/* 맨 밑에 있어야 css가 잘 먹힘 */

.up--start {
  opacity: 0;  
  transform: translateY(30px);
}

.up--end1 {
  opacity: 1;  
  transform: translateY(0px);
  transition : all 0.5s;
}

.slide-in00 { 
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
}

.slide-in01 {
  opacity: 1;
  width: 92%;
  /* width: 92%; */
  transition: width 0.6s ease-in;  
}

.fade-in00 {
  opacity: 0;
  transition: opacity 1s 0.2s ease-in;
}

.fade-in01 {
  opacity: 1;
}

.fade-in-self {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}


.display-in-block-pc {
  display: inline-block;
}

.display-in-block-mo {
  display: none;
}


.display-block-mo {
  display: none;
}


@media (max-width:834px) {
  .up--start-mo {
    opacity: 0;  
    transform: translateY(30px);
  }

  .up--end1-mo {
    opacity: 1 !important;  
    transform: translateY(0px);
    transition : all 0.5s;
  }

  .slide-in00-mo {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
  }

  .slide-in01-mo {
    opacity: 1;
    width: 92%;
    /* width: 92%; */
    transition: width 0.6s ease-in;  
  }

  .fade-in00-mo {
    opacity: 0;
    transition: opacity 1s 0.2s ease-in;
  }

  .fade-in01-mo {
    opacity: 1;
  }

  .display-in-block-pc {
    display: none;
  }  

  .display-none-mo {
    display: none;
  }

  .display-in-block-mo {
    display: inline-block;
  }

  .display-block-mo {
    display: block;
  }

}